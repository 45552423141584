import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

// SCSS
import { gutter } from "../style/style.module.scss";

const NotFoundPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="404: Page Not Found" />
      <RunReCaptcha action="404" />
      <div className={gutter}>
        <h1>Page Not Found</h1>
        <p>The requested page was not found. Sorry!</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
